<template>
  <ScbHome  v-if="appConfigs.general.name == 'Sincerely, Cinnabun'"/>
  <ObbHome  v-if="appConfigs.general.name == 'The Outback Bowl'"/>
  <CCHome  v-if="appConfigs.general.name == 'Crossword Clash'"/>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component';
import ScbHome from '@/components/home/ScbHome.vue';
import ObbHome from '@/components/home/ObbHome.vue';
import CCHome from '@/components/home/CCHome.vue';
import { APP_CONFIGS } from '@/main';
import { FrameControl } from '@/models/FrameControl';

@Options({
  components: {
    ScbHome,
    ObbHome,
    CCHome
  }
})
export default class HomeView extends Vue {
  
  get appConfigs(): FrameControl {
    return APP_CONFIGS;
  }

}
</script>

<style>

</style>
