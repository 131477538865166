import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = {
  key: 0,
  style: {"position":"relative"}
}
const _hoisted_3 = { class: "notification-count notification-count-lg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: "rounded-image-container",
      style: _normalizeStyle({ height: `${_ctx.width}px`, width: `${_ctx.width}px`, position: 'relative' }),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goToUser()))
    }, [
      _createElementVNode("img", {
        src: _ctx.thumbnailSource,
        onerror: "this.onerror=null; this.src='/img/profile_not_found.png'",
        style: _normalizeStyle({
             'max-height': `${_ctx.width}px !important`,
             'max-width': `${_ctx.width}px !important`,
             'min-height': `${_ctx.width}px !important`,
             'min-width': `${_ctx.width}px !important`,
             'object-fit': 'cover'
             })
      }, null, 12, _hoisted_1)
    ], 4),
    (_ctx.notifications > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.notifications), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}