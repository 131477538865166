import { createElementVNode as _createElementVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "commentlist p-0 m-0",
  style: {"border-bottom":"0 !important"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("ol", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<li class=\"comment even thread-even depth-1\" id=\"li-comment-1\" data-v-f75341d0><div id=\"comment-1\" class=\"comment-wrap\" data-v-f75341d0><div class=\"skeleton\" data-v-f75341d0><div class=\"skeleton-title w-50 mb-4\" data-v-f75341d0></div><ul class=\"skeleton-para list-unstyled mb-0 w-50\" data-v-f75341d0><li data-v-f75341d0></li><li data-v-f75341d0></li></ul></div></div></li>", 1)
  ])))
}