import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createVNode as _createVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  id: "slider",
  class: "slider-element min-vh-100 include-header"
}
const _hoisted_2 = {
  class: "slider-inner",
  style: {"background-image":"url('one-page/images/pet.jpg')"}
}
const _hoisted_3 = { class: "vertical-middle px-3" }
const _hoisted_4 = { class: "container" }
const _hoisted_5 = { class: "row align-items-center justify-content-start text-start h-100" }
const _hoisted_6 = { class: "col-md-9" }
const _hoisted_7 = { class: "input-group input-group-lg mt-2 mb-4" }
const _hoisted_8 = {
  key: 0,
  id: "content",
  class: "page-section"
}
const _hoisted_9 = { class: "content-wrap py-0" }
const _hoisted_10 = { class: "container" }
const _hoisted_11 = { class: "row justify-content-center" }
const _hoisted_12 = { class: "col-lg-8" }
const _hoisted_13 = { class: "block-testimonials card py-4" }
const _hoisted_14 = {
  id: "oc-testi",
  class: "owl-carousel testimonials-carousel carousel-widget",
  "data-margin": "0",
  "data-items": "1"
}
const _hoisted_15 = { class: "oc-item" }
const _hoisted_16 = { class: "row g-0 align-items-center" }
const _hoisted_17 = { class: "oc-item" }
const _hoisted_18 = { class: "row g-0 align-items-center" }
const _hoisted_19 = { class: "oc-item" }
const _hoisted_20 = { class: "row g-0 align-items-center" }
const _hoisted_21 = {
  key: 1,
  class: "page-section"
}
const _hoisted_22 = { class: "content-wrap py-0" }
const _hoisted_23 = { class: "container" }
const _hoisted_24 = { class: "table-responsive" }
const _hoisted_25 = { class: "table" }
const _hoisted_26 = { class: "h1 fw-bold mb-3 text-center" }
const _hoisted_27 = ["onClick"]
const _hoisted_28 = { class: "table-row" }
const _hoisted_29 = { key: 0 }
const _hoisted_30 = { key: 0 }
const _hoisted_31 = { key: 1 }
const _hoisted_32 = { key: 0 }
const _hoisted_33 = { key: 1 }
const _hoisted_34 = {
  key: 2,
  class: "page-section"
}
const _hoisted_35 = { class: "content-wrap py-0" }
const _hoisted_36 = { class: "container" }
const _hoisted_37 = { class: "table-responsive" }
const _hoisted_38 = { class: "table" }
const _hoisted_39 = { style: {"border":"0 !important"} }
const _hoisted_40 = { class: "h1 fw-bold mb-3 text-center" }
const _hoisted_41 = {
  key: 3,
  class: "page-section"
}
const _hoisted_42 = { class: "content-wrap py-0" }
const _hoisted_43 = { class: "container" }
const _hoisted_44 = { class: "table-responsive" }
const _hoisted_45 = { class: "table" }
const _hoisted_46 = { style: {"border":"0 !important"} }
const _hoisted_47 = { class: "h1 fw-bold mb-3 text-center" }
const _hoisted_48 = { class: "table-row-no-hover" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VetReviewPreview = _resolveComponent("VetReviewPreview")!
  const _component_LoadingVetReviewPreview = _resolveComponent("LoadingVetReviewPreview")!
  const _component_StarRating = _resolveComponent("StarRating")!
  const _component_LoadingVetDetails = _resolveComponent("LoadingVetDetails")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("section", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _cache[3] || (_cache[3] = _createElementVNode("div", {
                  class: "emphasis-title dark mb-5",
                  "data-bs-theme": "dark"
                }, [
                  _createElementVNode("h2", {
                    "data-animate": "fadeInUp faster",
                    class: "mb-0 fadeInUp faster animated",
                    style: {"font-size":"70px","font-weight":"700","font-family":"'Playfair Display', serif !important","line-height":"1.2"}
                  }, [
                    _createTextVNode(" Need a Vet? "),
                    _createElementVNode("br"),
                    _createTextVNode(" Search Rabbit Vets in Your Area ")
                  ])
                ], -1)),
                _createElementVNode("form", {
                  id: "pet-search",
                  onSubmit: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.searchForVet()), ["prevent"])),
                  style: {"max-width":"700px"}
                }, [
                  _createElementVNode("div", _hoisted_7, [
                    _withDirectives(_createElementVNode("input", {
                      class: "form-control border-0",
                      type: "search",
                      placeholder: "Search Location...",
                      "aria-label": "Search",
                      id: "search-vet-input",
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.vetLocationSearch) = $event))
                    }, null, 512), [
                      [_vModelText, _ctx.vetLocationSearch]
                    ]),
                    _cache[2] || (_cache[2] = _createElementVNode("button", {
                      class: "button button-large m-0 bg-color text-light h-op-08",
                      type: "submit",
                      value: "submit",
                      style: {"border-top-right-radius":"0.5rem","border-bottom-right-radius":"0.5rem"}
                    }, "Search", -1))
                  ])
                ], 32)
              ])
            ])
          ])
        ]),
        _cache[4] || (_cache[4] = _createElementVNode("img", {
          src: "one-page/images/page/pet/hero.svg",
          alt: "Image",
          style: {"position":"absolute","bottom":"-1px"}
        }, null, -1))
      ])
    ]),
    _cache[16] || (_cache[16] = _createElementVNode("div", { id: "vetResults" }, null, -1)),
    (_ctx.showIntro)
      ? (_openBlock(), _createElementBlock("section", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _cache[6] || (_cache[6] = _createStaticVNode("<div id=\"section-about\" class=\"text-center\" data-onepage-settings=\"{&quot;offset&quot;:70,&quot;speed&quot;:1250,&quot;easing&quot;:&quot;easeInOutExpo&quot;}\" data-v-4c49461f><div class=\"container\" data-v-4c49461f><div class=\"row justify-content-center mb-5\" data-v-4c49461f><h3 class=\"h1 fw-bold mb-3\" style=\"max-width:700px;\" data-v-4c49461f> Locate the best exotic animal vets in your area </h3><p class=\"lead mx-auto mb-5\" style=\"max-width:800px;\" data-v-4c49461f> Discover a vet directory tailored specifically for rabbits. We consolidate various sources to help you find top-notch, specialized care for your furry friends. Make sure to read our <a href=\"/vet/guidelines\" data-v-4c49461f>Guidelines</a> and leave a review of your rabbit care provider. </p><p class=\"mb-5\" style=\"font-size:16px;\" data-v-4c49461f><a href=\"/vet/about\" class=\"more-link\" data-v-4c49461f> Learn more <i class=\"uil uil-angle-right-b\" data-v-4c49461f></i></a></p></div></div></div>", 1)),
            _createElementVNode("div", _hoisted_10, [
              _cache[5] || (_cache[5] = _createElementVNode("div", { class: "row justify-content-center mb-5" }, [
                _createElementVNode("div", { class: "col-xl-6 col-lg-8 text-center" }, [
                  _createElementVNode("h3", { class: "h1 fw-bold mb-3" }, "Community Reviews")
                ])
              ], -1)),
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("div", _hoisted_14, [
                      _createElementVNode("div", _hoisted_15, [
                        _createElementVNode("div", _hoisted_16, [
                          (_ctx.getVetReview(0).reviewId)
                            ? (_openBlock(), _createBlock(_component_VetReviewPreview, {
                                key: 0,
                                review: _ctx.getVetReview(0)
                              }, null, 8, ["review"]))
                            : (_openBlock(), _createBlock(_component_LoadingVetReviewPreview, { key: 1 }))
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_17, [
                        _createElementVNode("div", _hoisted_18, [
                          (_ctx.getVetReview(1).reviewId)
                            ? (_openBlock(), _createBlock(_component_VetReviewPreview, {
                                key: 0,
                                review: _ctx.getVetReview(1)
                              }, null, 8, ["review"]))
                            : (_openBlock(), _createBlock(_component_LoadingVetReviewPreview, { key: 1 }))
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_19, [
                        _createElementVNode("div", _hoisted_20, [
                          (_ctx.getVetReview(2).reviewId)
                            ? (_openBlock(), _createBlock(_component_VetReviewPreview, {
                                key: 0,
                                review: _ctx.getVetReview(2)
                              }, null, 8, ["review"]))
                            : (_openBlock(), _createBlock(_component_LoadingVetReviewPreview, { key: 1 }))
                        ])
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (!_ctx.showIntro && _ctx.vets.length > 0 && !_ctx.isApiCallInProgress)
      ? (_openBlock(), _createElementBlock("section", _hoisted_21, [
          _createElementVNode("div", _hoisted_22, [
            _createElementVNode("div", _hoisted_23, [
              _createElementVNode("div", _hoisted_24, [
                _createElementVNode("table", _hoisted_25, [
                  _createElementVNode("thead", null, [
                    _createElementVNode("tr", null, [
                      _createElementVNode("th", null, [
                        _createElementVNode("h3", _hoisted_26, "Vets Near " + _toDisplayString(_ctx.lastSearch), 1)
                      ])
                    ])
                  ]),
                  _createElementVNode("tbody", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortedVets, (vet) => {
                      return (_openBlock(), _createElementBlock("tr", {
                        key: vet.googlePlaceId,
                        onClick: ($event: any) => (_ctx.onVetSearchResultClick(vet.googlePlaceId))
                      }, [
                        _createElementVNode("td", _hoisted_28, [
                          _createTextVNode(_toDisplayString(vet.name) + " ", 1),
                          _cache[13] || (_cache[13] = _createElementVNode("i", { class: "fa-solid fa-up-right-from-square" }, null, -1)),
                          _cache[14] || (_cache[14] = _createElementVNode("br", null, null, -1)),
                          _createElementVNode("p", null, [
                            _createElementVNode("small", null, _toDisplayString(vet.address), 1),
                            (vet.totalRatings > 0)
                              ? (_openBlock(), _createElementBlock("small", _hoisted_29, [
                                  _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1)),
                                  _createTextVNode(" " + _toDisplayString(Number(vet?.rating).toFixed(1)) + "   ", 1),
                                  _createVNode(_component_StarRating, {
                                    rating: vet.rating
                                  }, null, 8, ["rating"]),
                                  _createTextVNode("  (" + _toDisplayString((vet.totalRatings)) + " ", 1),
                                  (vet.totalRatings === 1)
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_30, "community review"))
                                    : (_openBlock(), _createElementBlock("span", _hoisted_31, "community reviews")),
                                  _cache[8] || (_cache[8] = _createTextVNode(") "))
                                ]))
                              : _createCommentVNode("", true),
                            _createElementVNode("small", null, [
                              _cache[11] || (_cache[11] = _createElementVNode("br", null, null, -1)),
                              _createTextVNode(" " + _toDisplayString(Number(vet?.googleRating).toFixed(1)) + "   ", 1),
                              _createVNode(_component_StarRating, {
                                rating: vet.googleRating
                              }, null, 8, ["rating"]),
                              _createTextVNode("  (" + _toDisplayString((vet.googleTotalRatings)) + " ", 1),
                              (vet.googleTotalRatings === 1)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_32, _cache[9] || (_cache[9] = [
                                    _createElementVNode("i", { class: "fa-brands fa-google" }, null, -1),
                                    _createTextVNode(" review")
                                  ])))
                                : (_openBlock(), _createElementBlock("span", _hoisted_33, _cache[10] || (_cache[10] = [
                                    _createElementVNode("i", { class: "fa-brands fa-google" }, null, -1),
                                    _createTextVNode(" reviews")
                                  ]))),
                              _cache[12] || (_cache[12] = _createTextVNode(") "))
                            ])
                          ])
                        ])
                      ], 8, _hoisted_27))
                    }), 128))
                  ])
                ])
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (!_ctx.showIntro && _ctx.vets.length == 0 && !_ctx.isApiCallInProgress)
      ? (_openBlock(), _createElementBlock("section", _hoisted_34, [
          _createElementVNode("div", _hoisted_35, [
            _createElementVNode("div", _hoisted_36, [
              _createElementVNode("div", _hoisted_37, [
                _createElementVNode("table", _hoisted_38, [
                  _createElementVNode("thead", null, [
                    _createElementVNode("tr", null, [
                      _createElementVNode("th", _hoisted_39, [
                        _createElementVNode("h3", _hoisted_40, "Vets Near " + _toDisplayString(_ctx.lastSearch), 1)
                      ])
                    ])
                  ]),
                  _cache[15] || (_cache[15] = _createElementVNode("tbody", null, [
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", { style: {"border":"0 !important"} }, [
                        _createTextVNode(" No results found. Try searching a different location or "),
                        _createElementVNode("a", { href: "/about#contact" }, "Contact Us"),
                        _createTextVNode(". ")
                      ])
                    ])
                  ], -1))
                ])
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.isApiCallInProgress)
      ? (_openBlock(), _createElementBlock("section", _hoisted_41, [
          _createElementVNode("div", _hoisted_42, [
            _createElementVNode("div", _hoisted_43, [
              _createElementVNode("div", _hoisted_44, [
                _createElementVNode("table", _hoisted_45, [
                  _createElementVNode("thead", null, [
                    _createElementVNode("tr", null, [
                      _createElementVNode("th", _hoisted_46, [
                        _createElementVNode("h3", _hoisted_47, "Vets Near " + _toDisplayString(_ctx.lastSearch), 1)
                      ])
                    ])
                  ]),
                  _createElementVNode("tbody", null, [
                    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(5, (index) => {
                      return _createElementVNode("tr", { key: index }, [
                        _createElementVNode("td", _hoisted_48, [
                          _createVNode(_component_LoadingVetDetails)
                        ])
                      ])
                    }), 64))
                  ])
                ])
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _cache[17] || (_cache[17] = _createStaticVNode("<div class=\"section bg-transparent my-0 py-0\" data-v-4c49461f><div class=\"container-fluid py-6\" data-v-4c49461f><div class=\"row justify-content-center mx-auto mw-md gx-5\" data-v-4c49461f><div class=\"col\" data-v-4c49461f><p class=\"h6 mb-0\" data-v-4c49461f> Our database combines multiple sources to generate a list of vets near your area. Still don&#39;t see your vet? Need to correct some bad information? Have questions about our database? <a href=\"/about#contact\" data-v-4c49461f>Contact Us</a></p></div></div></div></div>", 1))
  ], 64))
}