import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  class: "commentlist p-0 m-0",
  style: {"border-bottom":"0 !important"}
}
const _hoisted_2 = {
  class: "comment even thread-even depth-1",
  id: "li-comment-1"
}
const _hoisted_3 = {
  id: "comment-1",
  class: "comment-wrap"
}
const _hoisted_4 = { class: "comment-meta" }
const _hoisted_5 = { class: "comment-author vcard" }
const _hoisted_6 = ["href"]
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "comment-content" }
const _hoisted_9 = { class: "comment-author" }
const _hoisted_10 = ["href"]
const _hoisted_11 = { class: "d-flex" }
const _hoisted_12 = {
  key: 0,
  class: "ms-1"
}
const _hoisted_13 = { style: {"margin-top":"0.95rem"} }
const _hoisted_14 = ["innerHTML"]
const _hoisted_15 = { class: "review-images d-flex flex-wrap" }
const _hoisted_16 = ["href"]
const _hoisted_17 = ["src"]
const _hoisted_18 = {
  class: "dropdown-menu dropdown-menu-links rounded shadow-sm dropdown-menu-end py-0 m-0",
  "aria-labelledby": "editlink2"
}
const _hoisted_19 = {
  key: 1,
  class: "dropdown-item",
  href: "#",
  "data-bs-toggle": "modal",
  "data-bs-target": "#deleteReviewModal"
}
const _hoisted_20 = {
  class: "modal fade",
  id: "deleteReviewModal",
  tabindex: "-1",
  "aria-labelledby": "deleteReviewModalLabel",
  "aria-hidden": "true"
}
const _hoisted_21 = { class: "modal-dialog modal-dialog-centered" }
const _hoisted_22 = { class: "modal-content" }
const _hoisted_23 = { class: "modal-body" }
const _hoisted_24 = { class: "row p-4 bg-white d-flex align-items-center justify-content-center rounded" }
const _hoisted_25 = { class: "col-md-12 p-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StarRating = _resolveComponent("StarRating")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("ol", _hoisted_1, [
      _createElementVNode("li", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("a", {
                class: "comment-avatar",
                href: '/user/' + _ctx.review.userId
              }, [
                _createElementVNode("img", {
                  alt: "Image",
                  src: _ctx.review.user?.userThumbnail ? _ctx.assetHost + '/' + _ctx.review.user?.userThumbnail : '/img/profile_not_found.png',
                  onerror: "this.onerror=null; this.src='/img/profile_not_found.png'",
                  class: "avatar avatar-60 photo avatar-default",
                  height: "60",
                  width: "60"
                }, null, 8, _hoisted_7)
              ], 8, _hoisted_6)
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("a", {
                href: '/user/' + _ctx.review.userId
              }, _toDisplayString(_ctx.review.user?.username ?? "Unknown"), 9, _hoisted_10),
              _createElementVNode("span", _hoisted_11, [
                _createTextVNode(_toDisplayString(_ctx.timeSince(Number(_ctx.review.created))) + " ago ", 1),
                (_ctx.review.edited)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_12, "  (Edited " + _toDisplayString(_ctx.timeSince(Number(_ctx.review.edited))) + " ago)  ", 1))
                  : _createCommentVNode("", true)
              ])
            ]),
            _createElementVNode("div", _hoisted_13, [
              _createVNode(_component_StarRating, {
                rating: Number(_ctx.review.rating)
              }, null, 8, ["rating"])
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("div", {
                innerHTML: _ctx.review?.content,
                class: "review-body-text rendered-html"
              }, null, 8, _hoisted_14)
            ]),
            _createElementVNode("div", _hoisted_15, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.review.attachments, (attachment) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: attachment,
                  class: "mx-2 my-2"
                }, [
                  _createElementVNode("a", {
                    href: _ctx.assetHost + '/' + attachment,
                    target: "_blank"
                  }, [
                    _createElementVNode("img", {
                      src: _ctx.assetHost + '/' + attachment,
                      alt: "Image Description"
                    }, null, 8, _hoisted_17)
                  ], 8, _hoisted_16)
                ]))
              }), 128))
            ]),
            _cache[6] || (_cache[6] = _createElementVNode("a", {
              class: "comment-reply-link",
              href: "#",
              id: "editlink2",
              "data-bs-toggle": "dropdown",
              "aria-expanded": "false"
            }, [
              _createElementVNode("i", { class: "bi bi-three-dots" })
            ], -1)),
            _createElementVNode("div", _hoisted_18, [
              (_ctx.isOwnReview(_ctx.review.userId))
                ? (_openBlock(), _createElementBlock("a", {
                    key: 0,
                    class: "dropdown-item",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.editReview(_ctx.review.reviewId)))
                  }, _cache[3] || (_cache[3] = [
                    _createElementVNode("i", { class: "bi bi-pencil me-1" }, null, -1),
                    _createTextVNode(" Edit ")
                  ])))
                : _createCommentVNode("", true),
              (_ctx.isOwnReview(_ctx.review.userId))
                ? (_openBlock(), _createElementBlock("a", _hoisted_19, _cache[4] || (_cache[4] = [
                    _createElementVNode("i", { class: "bi bi-trash me-1" }, null, -1),
                    _createTextVNode(" Delete ")
                  ])))
                : _createCommentVNode("", true),
              (!_ctx.isOwnReview(_ctx.review.userId))
                ? (_openBlock(), _createElementBlock("a", {
                    key: 2,
                    class: "dropdown-item",
                    href: "#",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.flagContent('Review ' + _ctx.review.reviewId)))
                  }, _cache[5] || (_cache[5] = [
                    _createElementVNode("i", { class: "bi bi-flag me-1" }, null, -1),
                    _createTextVNode(" Flag ")
                  ])))
                : _createCommentVNode("", true)
            ])
          ]),
          _cache[7] || (_cache[7] = _createElementVNode("div", { class: "clear" }, null, -1))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_20, [
      _createElementVNode("div", _hoisted_21, [
        _createElementVNode("div", _hoisted_22, [
          _cache[10] || (_cache[10] = _createElementVNode("div", { class: "modal-header" }, [
            _createElementVNode("button", {
              type: "button",
              class: "btn-close",
              "data-bs-dismiss": "modal",
              "aria-label": "Close"
            })
          ], -1)),
          _createElementVNode("div", _hoisted_23, [
            _createElementVNode("div", _hoisted_24, [
              _createElementVNode("div", _hoisted_25, [
                _cache[8] || (_cache[8] = _createElementVNode("h3", { class: "mb-2" }, " Delete Review ", -1)),
                _cache[9] || (_cache[9] = _createElementVNode("p", { class: "mb-4 fw-normal text-smaller text-black-50" }, " Are you sure you want to delete this review? ", -1)),
                _createElementVNode("button", {
                  type: "button",
                  class: "button button-large m-0 bg-color text-light h-op-08",
                  onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.deleteReview && _ctx.deleteReview(...args)))
                }, "Delete")
              ])
            ])
          ])
        ])
      ])
    ])
  ], 64))
}