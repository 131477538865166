import { Post, PostResponse, SiteInfo } from "@/models/Cms";
import store, { VuexModuleNamespaces, DataStore } from '@/store';
import { getNewContent, getPopularContent, getPostByCategory, getPostBySearch, getPostByTitle, getSiteContentByTitle } from "@/service/post";


export async function loadNewContent(): Promise<Post[]> {
    // Check cache
    let posts = store.getters[`${VuexModuleNamespaces.App}/${DataStore.getters.getTrendingPosts.name}`];
    if (posts.length > 0) {
        return posts;
    } else {
        // Fetch content and update cache
        posts = await (await getNewContent()).data.data
        store.commit(`${VuexModuleNamespaces.App}/${DataStore.mutations.updateTrendingPosts.name}`, posts);
        store.commit(`${VuexModuleNamespaces.App}/${DataStore.mutations.updatePostCache.name}`, posts);
        return posts;
    }
}

export async function loadPopularContent(): Promise<Post[]> {
    // Check cache
    let posts = store.getters[`${VuexModuleNamespaces.App}/${DataStore.getters.getPopularPosts.name}`];
    if (posts.length > 0) {
        return posts;
    } else {
        // Fetch content and update cache
        posts = await (await getPopularContent()).data.data
        store.commit(`${VuexModuleNamespaces.App}/${DataStore.mutations.updatePopularPosts.name}`, posts);
        store.commit(`${VuexModuleNamespaces.App}/${DataStore.mutations.updatePostCache.name}`, posts);
        return posts;
    }
}

export async function queryPostByTitle(title: string): Promise<Post | undefined> {
    // Check cache
    const postCache = store.getters[`${VuexModuleNamespaces.App}/${DataStore.getters.getPostCache.name}`];
    if (postCache.has(title)) {
        return postCache.get(title);
    } else {
        // Fetch content and update cache
        const posts = await (await getPostByTitle(title)).data.data
        store.commit(`${VuexModuleNamespaces.App}/${DataStore.mutations.updatePostCache.name}`, posts);
        if (posts.length > 0) {
            return posts[0];
        }
    }
}

export async function getContentByCategory(category: string, page = 1): Promise<PostResponse> {
    const postResponse = await (await getPostByCategory(category, page)).data;
    return postResponse;
}

export async function getContentBySearch(search: string, page = 1): Promise<PostResponse> {
    const postResponse = await (await getPostBySearch(search, page)).data;
    return postResponse;
}

export async function getSiteInfoByTitle(title: string): Promise<SiteInfo | undefined> {
    const siteInfoResponse = await (await getSiteContentByTitle(title)).data;
    if (siteInfoResponse.data.length > 0) {
        return siteInfoResponse.data[0];
    }
}
