import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  id: "footer",
  class: "dark bg-black border-top-0"
}
const _hoisted_2 = { class: "container py-4" }
const _hoisted_3 = { class: "footer-widgets-wrap" }
const _hoisted_4 = { class: "row col-mb-50" }
const _hoisted_5 = { class: "col-md-4" }
const _hoisted_6 = { class: "widget subscribe-widget" }
const _hoisted_7 = { class: "input-group mx-auto" }
const _hoisted_8 = {
  class: "btn border-start-0",
  type: "submit"
}
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { class: "col-lg-3 col-6 offset-md-2" }
const _hoisted_11 = { class: "widget widget_links" }
const _hoisted_12 = { class: "mb-2" }
const _hoisted_13 = { class: "col-lg-3 col-6" }
const _hoisted_14 = { class: "widget widget_links" }
const _hoisted_15 = ["href"]
const _hoisted_16 = ["href"]
const _hoisted_17 = {
  id: "copyrights",
  style: {"border-top":"1px solid rgba(255,255,255,.2)"}
}
const _hoisted_18 = { class: "container" }
const _hoisted_19 = { class: "row col-mb-30" }
const _hoisted_20 = { class: "col-md-8 text-center text-md-end" }
const _hoisted_21 = { class: "copyright-links" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!

  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _cache[5] || (_cache[5] = _createElementVNode("h3", { class: "text-transform-none ls-0 border-bottom d-inline-block mb-5" }, "Subscribe to Our Newsletter", -1)),
              _cache[6] || (_cache[6] = _createElementVNode("p", null, "Get Important News, Offers, and Inside Scoops Right to Your Inbox", -1)),
              _cache[7] || (_cache[7] = _createElementVNode("div", { class: "widget-subscribe-form-result" }, null, -1)),
              _createElementVNode("form", {
                id: "widget-subscribe-form",
                onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.sendSubscribeMessage && _ctx.sendSubscribeMessage(...args)), ["prevent"])),
                class: "mb-0"
              }, [
                _createElementVNode("div", _hoisted_7, [
                  _withDirectives(_createElementVNode("input", {
                    type: "email",
                    id: "widget-subscribe-form-email",
                    name: "widget-subscribe-form-email",
                    class: "form-control required email",
                    placeholder: "Enter your Email",
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.subscribeEmail) = $event))
                  }, null, 512), [
                    [_vModelText, _ctx.subscribeEmail]
                  ]),
                  _createElementVNode("button", _hoisted_8, [
                    (_ctx.isApiCallInProgress)
                      ? (_openBlock(), _createBlock(_component_LoadingSpinner, { key: 0 }))
                      : (_openBlock(), _createElementBlock("span", _hoisted_9, _cache[4] || (_cache[4] = [
                          _createElementVNode("i", { class: "bi-arrow-right h-op-08" }, null, -1)
                        ])))
                  ])
                ])
              ], 32)
            ])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _cache[10] || (_cache[10] = _createElementVNode("h3", { class: "text-transform-none ls-0 border-bottom d-inline-block mb-5" }, "Contact Us", -1)),
              _createElementVNode("ul", null, [
                _createElementVNode("li", _hoisted_12, [
                  _createElementVNode("a", {
                    class: "ls-1 text-light",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.flagContent('Home'))),
                    style: {"cursor":"pointer"}
                  }, "Report Content")
                ]),
                _cache[8] || (_cache[8] = _createElementVNode("li", { class: "mb-2" }, [
                  _createElementVNode("a", {
                    class: "ls-1 text-light",
                    href: "/about#contact"
                  }, "Message Us")
                ], -1)),
                _cache[9] || (_cache[9] = _createElementVNode("li", { class: "mb-2" }, [
                  _createElementVNode("a", {
                    class: "ls-1 text-light",
                    href: "/signIn"
                  }, "Sign In/Register")
                ], -1))
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("div", _hoisted_14, [
              _cache[11] || (_cache[11] = _createElementVNode("h3", { class: "text-transform-none ls-0 border-bottom d-inline-block mb-5" }, "Follow Us", -1)),
              _createElementVNode("ul", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.entries(_ctx.appConfigs.social), ([key, value]) => {
                  return (_openBlock(), _createElementBlock("li", {
                    class: "mb-2",
                    key: key,
                    href: value,
                    target: "_blank"
                  }, [
                    _createElementVNode("a", {
                      class: "ls-1 text-light",
                      target: "_blank",
                      href: value
                    }, _toDisplayString(key.charAt(0).toUpperCase() + key.slice(1)), 9, _hoisted_16)
                  ], 8, _hoisted_15))
                }), 128))
              ])
            ])
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_17, [
      _createElementVNode("div", _hoisted_18, [
        _createElementVNode("div", _hoisted_19, [
          _cache[20] || (_cache[20] = _createElementVNode("div", { class: "col-md-4 text-center text-md-start" }, " Copyrights © 2024 All Rights Reserved ", -1)),
          _createElementVNode("div", _hoisted_20, [
            _createElementVNode("div", _hoisted_21, [
              _cache[12] || (_cache[12] = _createElementVNode("a", { href: "/siteInfo/terms" }, " Terms & Conditions ", -1)),
              _cache[13] || (_cache[13] = _createTextVNode(" / ")),
              _cache[14] || (_cache[14] = _createElementVNode("a", { href: "/siteInfo/privacyPolicy" }, " Privacy Policy ", -1)),
              _cache[15] || (_cache[15] = _createTextVNode(" / ")),
              _cache[16] || (_cache[16] = _createElementVNode("a", { href: "/siteInfo/help" }, " Help ", -1)),
              _cache[17] || (_cache[17] = _createTextVNode(" / ")),
              _cache[18] || (_cache[18] = _createElementVNode("a", { href: "/siteInfo/cookiePolicy" }, " Cookie Policy ", -1)),
              _cache[19] || (_cache[19] = _createTextVNode(" / ")),
              _createElementVNode("a", {
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$cookieConsent.showPreferences())),
                style: {"cursor":"pointer"}
              }, " Cookie Settings ")
            ])
          ])
        ])
      ])
    ])
  ]))
}