import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { id: "wrapper" }
const _hoisted_2 = {
  class: "position-fixed top-0 end-0 p-3",
  style: {"z-index":"99999"}
}
const _hoisted_3 = {
  id: "liveToast",
  class: "toast hide",
  role: "alert",
  "aria-live": "assertive",
  "aria-atomic": "true"
}
const _hoisted_4 = { class: "toast-body" }
const _hoisted_5 = { class: "bg-body" }
const _hoisted_6 = {
  class: "modal fade",
  id: "flagContentModal",
  tabindex: "-1",
  "aria-labelledby": "flagContentModalLabel",
  "aria-hidden": "true"
}
const _hoisted_7 = { class: "modal-dialog modal-dialog-centered" }
const _hoisted_8 = { class: "modal-content" }
const _hoisted_9 = { class: "modal-body" }
const _hoisted_10 = { class: "row p-4 bg-white d-flex align-items-center justify-content-center rounded" }
const _hoisted_11 = { class: "col-md-12 p-4" }
const _hoisted_12 = { class: "form-group" }
const _hoisted_13 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Footer = _resolveComponent("Footer")!
  const _component_BetaOverlay = _resolveComponent("BetaOverlay")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Header),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _cache[2] || (_cache[2] = _createElementVNode("i", { class: "bi bi-exclamation-triangle-fill" }, null, -1)),
            _createTextVNode(" " + _toDisplayString(_ctx.errorMessage), 1)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_router_view)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _cache[6] || (_cache[6] = _createElementVNode("div", { class: "modal-header" }, [
              _createElementVNode("button", {
                type: "button",
                class: "btn-close",
                "data-bs-dismiss": "modal",
                "aria-label": "Close"
              })
            ], -1)),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, [
                  _cache[3] || (_cache[3] = _createElementVNode("h3", { class: "mb-2" }, " Flag Content ", -1)),
                  _cache[4] || (_cache[4] = _createElementVNode("p", { class: "mb-4 fw-normal text-smaller text-black-50" }, " Thanks for reporting things that look suspicious or that break the rules. Let us know what's happening, and we'll look into it. ", -1)),
                  _cache[5] || (_cache[5] = _createElementVNode("p", { class: "mb-4 fw-normal text-smaller text-black-50" }, " Please provide us with as much detail as possible regarding the content you are reporting. Your input will help us investigate and take appropriate action accordingly. ", -1)),
                  _createElementVNode("div", _hoisted_12, [
                    _withDirectives(_createElementVNode("textarea", {
                      class: "form-control mt-3 mb-1",
                      placeholder: "Flag Content",
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.reportContent) = $event)),
                      rows: "4"
                    }, null, 512), [
                      [_vModelText, _ctx.reportContent]
                    ])
                  ]),
                  _createElementVNode("button", {
                    type: "button",
                    class: "button button-large m-0 bg-color text-light h-op-08",
                    "data-bs-dismiss": "modal",
                    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.submitReport && _ctx.submitReport(...args)))
                  }, "Submit")
                ])
              ])
            ])
          ])
        ])
      ]),
      _createVNode(_component_Footer)
    ]),
    (_ctx.isBetaDeploy)
      ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
          _createVNode(_component_BetaOverlay)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}