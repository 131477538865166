import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "textEditor" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  for: "file-upload",
  class: "button button-small button-border border-color color h-bg-color h-text-light me-2 image-and-video"
}
const _hoisted_5 = {
  key: 1,
  class: "d-flex flex-wrap"
}
const _hoisted_6 = { class: "thumbnail-container" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "thumbnail-container" }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QuillEditor = _resolveComponent("QuillEditor")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.enableImage)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.enableImage)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("label", _hoisted_4, [
                  _createElementVNode("input", {
                    id: "file-upload",
                    type: "file",
                    accept: "image/png, image/jpeg",
                    multiple: "",
                    style: {"display":"none"},
                    onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onFileChange && _ctx.onFileChange(...args)))
                  }, null, 32),
                  _cache[2] || (_cache[2] = _createElementVNode("i", { class: "fa-solid fa-paperclip" }, null, -1)),
                  _cache[3] || (_cache[3] = _createTextVNode(" Attachments "))
                ])
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.imagePreview)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.editAttachments, (image, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: image,
              class: "mx-2 mb-2"
            }, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("span", {
                  class: "thumbnail-icon",
                  onClick: ($event: any) => (_ctx.removeEditImage(index))
                }, _cache[4] || (_cache[4] = [
                  _createElementVNode("i", {
                    class: "fa-regular fa-circle-xmark",
                    style: {"background-color":"white"}
                  }, null, -1)
                ]), 8, _hoisted_7),
                _createElementVNode("img", {
                  src: _ctx.assetHost + '/' + image,
                  class: "preview-image"
                }, null, 8, _hoisted_8)
              ])
            ]))
          }), 128)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.previewImages, (image, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: image.id,
              class: "mx-2 mb-2"
            }, [
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("span", {
                  class: "thumbnail-icon",
                  onClick: ($event: any) => (_ctx.removeImage(index))
                }, _cache[5] || (_cache[5] = [
                  _createElementVNode("i", {
                    class: "fa-regular fa-circle-xmark",
                    style: {"background-color":"white"}
                  }, null, -1)
                ]), 8, _hoisted_10),
                _createElementVNode("img", {
                  src: image.source,
                  class: "preview-image"
                }, null, 8, _hoisted_11)
              ])
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", null, [
      _createVNode(_component_QuillEditor, {
        toolbar: _ctx.toolbarOptions,
        theme: "snow",
        contentType: "html",
        content: _ctx.content,
        "onUpdate:content": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.content) = $event))
      }, null, 8, ["toolbar", "content"])
    ])
  ]))
}