import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "skeleton" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("div", { class: "skeleton-title w-50 mb-4" }, null, -1),
    _createElementVNode("ul", { class: "skeleton-para list-unstyled mb-0 w-50" }, [
      _createElementVNode("li"),
      _createElementVNode("li"),
      _createElementVNode("li")
    ], -1)
  ])))
}