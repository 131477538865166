import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "overlay"
}
const _hoisted_2 = { class: "overlay-content" }
const _hoisted_3 = { class: "input-group input-group-lg mt-2 mb-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.showOverlay)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _cache[2] || (_cache[2] = _createElementVNode("p", null, "This site is under construction! Please enter the password to continue:", -1)),
          _createElementVNode("div", _hoisted_3, [
            _withDirectives(_createElementVNode("input", {
              class: "form-control border-0",
              type: "password",
              placeholder: "Enter Password...",
              "aria-label": "Password",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.password) = $event))
            }, null, 512), [
              [_vModelText, _ctx.password]
            ]),
            _createElementVNode("button", {
              class: "button button-large m-0 bg-color text-light h-op-08",
              type: "submit",
              value: "submit",
              style: {"border-top-right-radius":"0.5rem","border-bottom-right-radius":"0.5rem"},
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.checkPassword && _ctx.checkPassword(...args)))
            }, "Submit")
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}