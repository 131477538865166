import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "entry",
  style: {"display":"flex","flex-direction":"column","height":"100%","position":"relative"}
}
const _hoisted_2 = { style: {"flex":"1 0 auto"} }
const _hoisted_3 = { class: "entry-image mb-3" }
const _hoisted_4 = ["href"]
const _hoisted_5 = ["src"]
const _hoisted_6 = { style: {"flex":"0 0 auto"} }
const _hoisted_7 = { class: "entry-title title-sm" }
const _hoisted_8 = ["href"]
const _hoisted_9 = { class: "entry-categories" }
const _hoisted_10 = ["href"]
const _hoisted_11 = { class: "entry-meta" }
const _hoisted_12 = { class: "entry-content mt-3" }
const _hoisted_13 = { class: "block-ellipsis" }
const _hoisted_14 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("article", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("a", {
          href: "/post/" + _ctx.post.attributes.title
        }, [
          _createElementVNode("img", {
            src: _ctx.post.attributes.thumbnail.data.attributes.formats.small.url,
            loading: "lazy",
            class: "image-preview"
          }, null, 8, _hoisted_5)
        ], 8, _hoisted_4)
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("h3", null, [
          _createElementVNode("a", {
            href: "/post/" + _ctx.post.attributes.title,
            class: "color-underline stretched-link"
          }, _toDisplayString(_ctx.post.attributes.title), 9, _hoisted_8)
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("a", {
            href: "/post?category=" + _ctx.post.attributes.category.data.attributes.name
          }, _toDisplayString(_ctx.post.attributes.category.data.attributes.name), 9, _hoisted_10)
        ])
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("ul", null, [
          _createElementVNode("li", null, [
            _createTextVNode(_toDisplayString(_ctx.convertTimeToDate(_ctx.post.attributes.createdAt)) + " by ", 1),
            _createElementVNode("a", null, _toDisplayString(_ctx.post.attributes.author.data.attributes.name), 1)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.post.attributes.description), 1),
        _createElementVNode("a", {
          href: "/post/" + _ctx.post.attributes.title,
          class: "more-link"
        }, "Read More", 8, _hoisted_14)
      ])
    ])
  ]))
}