import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createBlock as _createBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "mb-0 bg-transparent mt-5" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "row align-items-center col-mb-30" }
const _hoisted_4 = { class: "col-lg-4" }
const _hoisted_5 = { class: "display-2 font-secondary text-stretch text-uppercase fw-bolder ls-1" }
const _hoisted_6 = { class: "op-07 fw-light" }
const _hoisted_7 = ["href"]
const _hoisted_8 = ["href"]
const _hoisted_9 = ["href"]
const _hoisted_10 = ["href"]
const _hoisted_11 = ["href"]
const _hoisted_12 = { class: "col-lg-8 d-flex justify-content-center" }
const _hoisted_13 = ["src"]
const _hoisted_14 = { class: "section mb-0 bg-transparent mt-0" }
const _hoisted_15 = { class: "container" }
const _hoisted_16 = { class: "row align-items-center col-mb-30" }
const _hoisted_17 = { class: "toggle" }
const _hoisted_18 = { class: "toggle-header justify-content-between flex-row-reverse" }
const _hoisted_19 = { class: "toggle-title h5 mb-0 fw-medium" }
const _hoisted_20 = {
  class: "toggle-content op-07 mt-2",
  style: {"display":"none"}
}
const _hoisted_21 = { class: "section mb-0 bg-transparent mt-0" }
const _hoisted_22 = { class: "container" }
const _hoisted_23 = { class: "row align-items-center col-mb-30" }
const _hoisted_24 = { class: "mt-4" }
const _hoisted_25 = ["href"]
const _hoisted_26 = {
  class: "section mb-0 bg-transparent mt-0",
  id: "contact"
}
const _hoisted_27 = { class: "container" }
const _hoisted_28 = { class: "row align-items-center col-mb-30" }
const _hoisted_29 = { class: "row g-3" }
const _hoisted_30 = {
  class: "row g-3 needs-validation",
  novalidate: ""
}
const _hoisted_31 = { class: "col-12" }
const _hoisted_32 = { class: "form-floating" }
const _hoisted_33 = { class: "col-12" }
const _hoisted_34 = { class: "form-floating" }
const _hoisted_35 = {
  class: "button button-large m-0 bg-color text-light h-op-08",
  type: "submit",
  id: "template-contactform-submit",
  name: "template-contactform-submit",
  value: "submit"
}
const _hoisted_36 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _cache[7] || (_cache[7] = _createElementVNode("div", { class: "hstack mb-3" }, [
              _createElementVNode("span", { class: "col-auto me-3 text-uppercase ls-3 text-smaller color" }, "About Us"),
              _createElementVNode("div", { class: "border-top border-light w-100 border-color" })
            ], -1)),
            _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.appConfigs.layout.about.bioHeader), 1),
            _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.appConfigs.layout.about.bio), 1),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.entries(_ctx.appConfigs.social), ([key, value]) => {
              return (_openBlock(), _createElementBlock("span", {
                key: key,
                href: value
              }, [
                (key === 'youtube')
                  ? (_openBlock(), _createElementBlock("a", {
                      key: 0,
                      href: value,
                      class: "social-icon bg-light rounded-circle border-transparent si-small h-bg-youtube"
                    }, _cache[3] || (_cache[3] = [
                      _createElementVNode("i", { class: "fa-brands fa-youtube" }, null, -1),
                      _createElementVNode("i", { class: "fa-brands fa-youtube" }, null, -1)
                    ]), 8, _hoisted_8))
                  : _createCommentVNode("", true),
                (key === 'instagram')
                  ? (_openBlock(), _createElementBlock("a", {
                      key: 1,
                      href: value,
                      class: "social-icon bg-light rounded-circle border-transparent si-small h-bg-instagram"
                    }, _cache[4] || (_cache[4] = [
                      _createElementVNode("i", { class: "fa-brands fa-instagram" }, null, -1),
                      _createElementVNode("i", { class: "fa-brands fa-instagram" }, null, -1)
                    ]), 8, _hoisted_9))
                  : _createCommentVNode("", true),
                (key === 'amazon')
                  ? (_openBlock(), _createElementBlock("a", {
                      key: 2,
                      href: value,
                      class: "social-icon bg-light rounded-circle border-transparent si-small h-bg-amazon"
                    }, _cache[5] || (_cache[5] = [
                      _createElementVNode("i", { class: "fa-brands fa-amazon" }, null, -1),
                      _createElementVNode("i", { class: "fa-brands fa-amazon" }, null, -1)
                    ]), 8, _hoisted_10))
                  : _createCommentVNode("", true),
                (key === 'tiktok')
                  ? (_openBlock(), _createElementBlock("a", {
                      key: 3,
                      href: value,
                      target: "_blank",
                      class: "social-icon bg-light rounded-circle border-transparent si-small h-bg-tiktok"
                    }, _cache[6] || (_cache[6] = [
                      _createElementVNode("i", { class: "fa-brands fa-tiktok" }, null, -1),
                      _createElementVNode("i", { class: "fa-brands fa-tiktok" }, null, -1)
                    ]), 8, _hoisted_11))
                  : _createCommentVNode("", true)
              ], 8, _hoisted_7))
            }), 128))
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("img", {
              src: _ctx.appConfigs.assets.aboutImage,
              alt: "..",
              class: "rounded-6 fadeInUpSmall animated max-height-img",
              "data-animate": "fadeInUpSmall"
            }, null, 8, _hoisted_13)
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_14, [
      _cache[10] || (_cache[10] = _createElementVNode("h2", { class: "h1 fw-normal text-center mb-6" }, "Frequently Asked Questions", -1)),
      _createElementVNode("div", _hoisted_15, [
        _createElementVNode("div", _hoisted_16, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.appConfigs.layout.about.faq, (faq) => {
            return (_openBlock(), _createElementBlock("div", {
              key: faq.question
            }, [
              _createElementVNode("div", _hoisted_17, [
                _createElementVNode("div", _hoisted_18, [
                  _cache[8] || (_cache[8] = _createElementVNode("div", { class: "toggle-icon" }, [
                    _createElementVNode("i", { class: "toggle-closed uil uil-plus" }),
                    _createElementVNode("i", { class: "toggle-open uil uil-minus" })
                  ], -1)),
                  _createElementVNode("div", _hoisted_19, _toDisplayString(faq.question), 1)
                ]),
                _createElementVNode("div", _hoisted_20, _toDisplayString(faq.answer), 1)
              ]),
              _cache[9] || (_cache[9] = _createElementVNode("div", { class: "line border-color my-4 op-01" }, null, -1))
            ]))
          }), 128))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_21, [
      _cache[13] || (_cache[13] = _createElementVNode("h2", { class: "h1 fw-normal text-center mb-6" }, "Support Us ", -1)),
      _createElementVNode("div", _hoisted_22, [
        _createElementVNode("div", _hoisted_23, [
          _cache[12] || (_cache[12] = _createElementVNode("div", { class: "op-07" }, " Love what we do? Help us keep it running smoothly! Buy us a coffee to support the hosting costs of our website. Your contribution goes directly towards maintaining our platform and bringing you more of what you love. Thank you for your support! ", -1)),
          _createElementVNode("div", _hoisted_24, [
            _createElementVNode("a", {
              href: _ctx.appConfigs.general.supportLink,
              target: "_blank"
            }, _cache[11] || (_cache[11] = [
              _createElementVNode("img", {
                src: "https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png",
                alt: "Buy Me A Coffee",
                style: {"height":"60px !important","width":"217px !important"}
              }, null, -1)
            ]), 8, _hoisted_25)
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_26, [
      _cache[18] || (_cache[18] = _createElementVNode("div", { class: "text-center mb-5" }, [
        _createElementVNode("p", { class: "color text-uppercase ls-3 small mb-3" }, "Get in Touch"),
        _createElementVNode("h2", { class: "h1 fw-normal text-center mb-6" }, "Contact Us")
      ], -1)),
      _createElementVNode("div", _hoisted_27, [
        _createElementVNode("div", _hoisted_28, [
          _createElementVNode("form", {
            onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.sendContactEmail && _ctx.sendContactEmail(...args)), ["prevent"]))
          }, [
            _createElementVNode("div", _hoisted_29, [
              _createElementVNode("form", _hoisted_30, [
                _createElementVNode("div", _hoisted_31, [
                  _createElementVNode("div", _hoisted_32, [
                    _withDirectives(_createElementVNode("input", {
                      type: "email",
                      class: "required form-control rounded-0 text-center text-md-start",
                      id: "email",
                      name: "email",
                      placeholder: "Email",
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.contactEmail) = $event)),
                      required: ""
                    }, null, 512), [
                      [_vModelText, _ctx.contactEmail]
                    ]),
                    _cache[14] || (_cache[14] = _createElementVNode("label", { for: "email" }, "Email", -1)),
                    _cache[15] || (_cache[15] = _createElementVNode("div", { class: "invalid-feedback" }, " Email required. ", -1))
                  ])
                ]),
                _createElementVNode("div", _hoisted_33, [
                  _createElementVNode("div", _hoisted_34, [
                    _withDirectives(_createElementVNode("textarea", {
                      class: "required form-control rounded-0 text-center text-md-start",
                      id: "message",
                      name: "message",
                      placeholder: "Leave a message here",
                      style: {"height":"130px"},
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.contactMessage) = $event)),
                      required: ""
                    }, "                           ", 512), [
                      [_vModelText, _ctx.contactMessage]
                    ]),
                    _cache[16] || (_cache[16] = _createElementVNode("label", { for: "message" }, "Message", -1)),
                    _cache[17] || (_cache[17] = _createElementVNode("div", { class: "invalid-feedback" }, " Message required. ", -1))
                  ])
                ]),
                _createElementVNode("div", null, [
                  _createElementVNode("button", _hoisted_35, [
                    (_ctx.isApiCallInProgress)
                      ? (_openBlock(), _createBlock(_component_LoadingSpinner, { key: 0 }))
                      : (_openBlock(), _createElementBlock("span", _hoisted_36, "Send Message"))
                  ])
                ])
              ])
            ])
          ], 32)
        ])
      ])
    ])
  ], 64))
}