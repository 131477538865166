import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = {
  key: 1,
  href: "/vet"
}
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-md-6" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "my-2" }
const _hoisted_7 = { class: "my-2" }
const _hoisted_8 = {
  key: 0,
  class: "my-2"
}
const _hoisted_9 = ["href"]
const _hoisted_10 = { class: "my-2 mb-5" }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { key: 1 }
const _hoisted_14 = { key: 1 }
const _hoisted_15 = { key: 2 }
const _hoisted_16 = { key: 1 }
const _hoisted_17 = { class: "col-md-6" }
const _hoisted_18 = { class: "google-map" }
const _hoisted_19 = ["src"]
const _hoisted_20 = { style: {"margin-bottom":"150px"} }
const _hoisted_21 = { key: 0 }
const _hoisted_22 = {
  key: 1,
  class: "my-5 text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_StarRating = _resolveComponent("StarRating")!
  const _component_LoadingVetDetails = _resolveComponent("LoadingVetDetails")!
  const _component_VetReviewPost = _resolveComponent("VetReviewPost")!
  const _component_LoadingVetUserReview = _resolveComponent("LoadingVetUserReview")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.cityRef)
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 0,
            to: `/vet?city=${_ctx.cityRef}`
          }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createElementVNode("button", { class: "button m-0 bg-color text-light h-op-08 mt-5 mb-3" }, "Back", -1)
            ])),
            _: 1
          }, 8, ["to"]))
        : (_openBlock(), _createElementBlock("a", _hoisted_2, _cache[2] || (_cache[2] = [
            _createElementVNode("button", { class: "button m-0 bg-color text-light h-op-08 mt-5 mb-3" }, "Back", -1)
          ]))),
      _cache[12] || (_cache[12] = _createElementVNode("br", null, null, -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (!_ctx.isApiCallInProgress)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("h2", null, _toDisplayString(_ctx.vet?.name), 1),
                _createElementVNode("div", _hoisted_6, [
                  _cache[3] || (_cache[3] = _createElementVNode("i", { class: "fa-solid fa-location-dot" }, null, -1)),
                  _createTextVNode(" " + _toDisplayString(_ctx.vet?.address), 1)
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _cache[4] || (_cache[4] = _createElementVNode("i", { class: "fa-solid fa-phone" }, null, -1)),
                  _createTextVNode(" " + _toDisplayString(_ctx.vet?.phone), 1)
                ]),
                (_ctx.vet?.website)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _cache[5] || (_cache[5] = _createElementVNode("i", { class: "fa-solid fa-link" }, null, -1)),
                      _createElementVNode("a", {
                        href: _ctx.vet?.website,
                        target: "_blank"
                      }, " Website ", 8, _hoisted_9)
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_10, [
                  (_ctx.vet?.reviews && _ctx.vet.reviews.length > 0)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_11, [
                        _createTextVNode(_toDisplayString(Number(_ctx.vet?.rating).toFixed(1)) + "   ", 1),
                        _createVNode(_component_StarRating, {
                          rating: _ctx.vet?.rating
                        }, null, 8, ["rating"]),
                        _createTextVNode("  (" + _toDisplayString((_ctx.vet?.totalRatings)) + " community ", 1),
                        (_ctx.vet.totalRatings === 1)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_12, "review"))
                          : (_openBlock(), _createElementBlock("span", _hoisted_13, "reviews")),
                        _cache[6] || (_cache[6] = _createTextVNode(") ")),
                        _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1))
                      ]))
                    : _createCommentVNode("", true),
                  _createTextVNode(" " + _toDisplayString(Number(_ctx.vet?.googleRating).toFixed(1)) + "   ", 1),
                  _createVNode(_component_StarRating, {
                    rating: _ctx.vet?.googleRating
                  }, null, 8, ["rating"]),
                  _createTextVNode("  (" + _toDisplayString((_ctx.vet?.googleTotalRatings)) + " ", 1),
                  (_ctx.vet?.googleTotalRatings === 1)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_14, _cache[8] || (_cache[8] = [
                        _createElementVNode("i", { class: "fa-brands fa-google" }, null, -1),
                        _createTextVNode(" review")
                      ])))
                    : (_openBlock(), _createElementBlock("span", _hoisted_15, _cache[9] || (_cache[9] = [
                        _createElementVNode("i", { class: "fa-brands fa-google" }, null, -1),
                        _createTextVNode(" reviews")
                      ]))),
                  _cache[10] || (_cache[10] = _createTextVNode(") "))
                ])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_16, [
                _createVNode(_component_LoadingVetDetails)
              ]))
        ]),
        _createElementVNode("div", _hoisted_17, [
          _createElementVNode("div", _hoisted_18, [
            _createElementVNode("iframe", {
              src: `https://www.google.com/maps/embed/v1/place?key=${_ctx.API_KEY}&q=${_ctx.vet?.address}`,
              style: {"width":"100%","min-height":"300px"},
              loading: "lazy"
            }, null, 8, _hoisted_19)
          ])
        ])
      ]),
      _createElementVNode("button", {
        class: "button button-border border-color mt-2 m-md-0 color h-bg-color h-text-light",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.addReview && _ctx.addReview(...args)))
      }, " Add Review "),
      _cache[13] || (_cache[13] = _createElementVNode("hr", null, null, -1)),
      _createElementVNode("div", _hoisted_20, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortedReviews, (review) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "mb-3",
            key: review.vetId
          }, [
            _createVNode(_component_VetReviewPost, {
              review: review,
              vetId: _ctx.postId
            }, null, 8, ["review", "vetId"])
          ]))
        }), 128)),
        (_ctx.isApiCallInProgress)
          ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
              (_openBlock(), _createElementBlock(_Fragment, null, _renderList(3, (index) => {
                return _createVNode(_component_LoadingVetUserReview, {
                  class: "mb-3",
                  key: index
                })
              }), 64))
            ]))
          : _createCommentVNode("", true),
        (!_ctx.isApiCallInProgress && _ctx.reviews.length === 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_22, _cache[11] || (_cache[11] = [
              _createElementVNode("div", { class: "feature-box fbox-effect flex-column" }, [
                _createElementVNode("div", { class: "fbox-content" }, [
                  _createElementVNode("h3", null, "No Reviews"),
                  _createElementVNode("p", null, "Be the first to share what you think")
                ])
              ], -1)
            ])))
          : _createCommentVNode("", true)
      ])
    ]),
    _cache[14] || (_cache[14] = _createStaticVNode("<div class=\"section bg-transparent my-0 py-0\"><div class=\"container-fluid py-6\"><div class=\"row justify-content-center mx-auto mw-md gx-5\"><div class=\"col\"><p class=\"h6 mb-0\"> Our database combines multiple sources to generate a list of vets near your area. Still don&#39;t see your vet? Need to correct some bad information? Have questions about our database? <a href=\"/about#contact\">Contact Us</a></p></div></div></div></div>", 1))
  ], 64))
}