export function convertTimeToDate(dateString: string): string {
    return new Date(dateString).toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
}

export function timestampToMMDDYYYY(timestamp: number): string {
  const date = new Date(timestamp * 1000); // Convert seconds to milliseconds
  return date.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
}

export function timeSince(epochSeconds: undefined | number): string {

  if (epochSeconds === undefined) {
    return "";
  }

  const seconds = Math.floor(((new Date().getTime() / 1000) - epochSeconds));

  // Check years
  let interval = seconds / 31622400;
  if (interval > 1) {
    const roundedInterval = Math.floor(interval);
    if (roundedInterval === 1) {
      return roundedInterval  + " year";
    } else {
      return roundedInterval  + " years";
    }
  }

  // Check months
  interval = seconds / 2592000;
  if (interval > 1) {
    const roundedInterval = Math.floor(interval);
    if (roundedInterval === 1) {
      return roundedInterval  + " month";
    } else {
      return roundedInterval  + " months";
    }
  }

  // Check seconds
  interval = seconds / 86400;
  if (interval > 1) {
    const roundedInterval = Math.floor(interval);
    if (roundedInterval === 1) {
      return roundedInterval  + " day";
    } else {
      return roundedInterval  + " days";
    }
  }

  // Check hours
  interval = seconds / 3600;
  if (interval > 1) {
    const roundedInterval = Math.floor(interval);
    if (roundedInterval === 1) {
      return roundedInterval  + " hour";
    } else {
      return roundedInterval  + " hours";
    }
  }

  // Check minutes
  interval = seconds / 60;
  if (interval > 1) {
    const roundedInterval = Math.floor(interval);
    if (roundedInterval === 1) {
      return roundedInterval  + " minute";
    } else {
      return roundedInterval  + " minutes";
    }
  }

  // Check seconds
  const roundedInterval = Math.floor(seconds);
  if (roundedInterval === 1) {
    return roundedInterval  + " second";
  } else {
    return roundedInterval  + " seconds";
  }
}

export function getEpochSeconds(): number {
  return Math.floor(Date.now() / 1000);
} 