import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/home/HomeView.vue'
import { FrameControl } from '@/models/FrameControl';
import config from '../../configs/configs.json';
import VetHomeView from '@/views/vet/VetHomeView.vue';
import VetPostView from '@/views/vet/VetPostView.vue';
import CommunityPostFeedView from '@/views/community/CommunityPostFeedView.vue';

export const CONFIGS: FrameControl = config;

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { title: config.general.metadata.home }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/about/AboutView.vue'),
    meta: { title: config.general.metadata.about }
  },
  {
    path: '/siteInfo/terms',
    name: 'siteInfoTerms',
    component: () => import('../views/site/TermsOfServiceView.vue'),
    meta: { title: config.general.metadata.termsOfService }
  },
  {
    path: '/siteInfo/cookiePolicy',
    name: 'siteInfoCookiePolicy',
    component: () => import('../views/site/CookiePolicyView.vue'),
    meta: { title: config.general.metadata.cookiePolicy }
  },
  {
    path: '/siteInfo/privacyPolicy',
    name: 'siteInfoPrivacyPolicy',
    component: () => import('../views/site/PrivacyPolicyView.vue'),
    meta: { title: config.general.metadata.privacyPolicy }
  },
  {
    path: '/siteInfo/help',
    name: 'siteInfoHelp',
    component: () => import('../views/site/HelpView.vue'),
    meta: { title: config.general.metadata.help }
  },
  {
    path: '/post',
    name: 'postHome',
    component: () => import('../views/post/PostHomeView.vue'),
    meta: { title: config.general.metadata.postHome }
  },
  {
    path: '/search',
    name: 'searchHome',
    component: () => import('../views/post/SearchHomeView.vue'),
    meta: { title: config.general.metadata.search }
  },
  {
    path: '/post/:postId',
    name: 'post',
    component: () => import('../views/post/PostView.vue'),
    meta: { title: config.general.metadata.post }
  },
  {
    path: '/callback',
    name: 'callback',
    component: () => import('../views/user/CallbackUserView.vue'),
    meta: { title: config.general.metadata.callbackUser }
  },
  {
    path: '/confirm',
    name: 'confirm',
    component: () => import('../views/user/ConfirmUserView.vue'),
    meta: { title: config.general.metadata.confirmUser }
  },
  {
    path: '/confirm/user',
    name: 'confirmUser',
    component: () => import('../views/user/ConfirmUsernameView.vue'),
    meta: { title: config.general.metadata.confirmUsername }
  },
  {
    path: '/signIn',
    name: 'signIn',
    component: () => import('../views/user/SignInView.vue'),
    meta: { title: config.general.metadata.signIn }
  },
  {
    path: '/signUp',
    name: 'signUp',
    component: () => import('../views/user/SignUpView.vue'),
    meta: { title: config.general.metadata.signUp }
  },
  {
    path: '/resetPassword',
    name: 'resetPassword',
    component: () => import('../views/user/ResetPasswordView.vue'),
    meta: { title: config.general.metadata.forgotPassword }
  },
  {
    path: '/resetPassword/confirm',
    name: 'resetPasswordConfirm',
    component: () => import('../views/user/ResetPasswordConfirmView.vue'),
    meta: { title: config.general.metadata.confirmResetPassword }
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('../views/user/ProfileView.vue'),
    meta: { title: config.general.metadata.profile }
  },
  {
    path: '/profile/notifications',
    name: 'profileNotifications',
    component: () => import('../views/user/UserNotification.vue'),
    meta: { title: config.general.metadata.notifications }
  },
  {
    path: '/user/:userId',
    name: 'user',
    component: () => import('../views/user/UserView.vue'),
    meta: { title: config.general.metadata.user }
  },
  ...(config.customPages.some(page => page.component === 'vet')
    ? [
        {
          path: '/vet',
          name: 'vet',
          component: VetHomeView,
          meta: { title: "Search Vets - Sincerely, Cinnabun" }
        },
        {
          path: '/vet/about',
          name: 'vetAbout',
          component: () => import('../views/vet/VetAboutView.vue'),
          meta: { title: "About Vet Database - Sincerely, Cinnabun" }
        },
        {
          path: '/vet/guidelines',
          name: 'vetGuidelines',
          component: () => import('../views/vet/VetGuidelinesView.vue'),
          meta: { title: "Vet Database Guidelines - Sincerely, Cinnabun" }
        },
        {
          path: '/vet/:postId',
          name: 'vetPost',
          component: VetPostView,
          meta: { title: " - Sincerely, Cinnabun" }
        },
        {
          path: '/vet/:postId/review',
          name: 'vetAddReview',
          component: () => import('../views/vet/VetAddReviewView.vue'),
          meta: { title: "Add Vet Review - Sincerely, Cinnabun" }
        }
      ]
    : []
  ),
  ...(config.customPages.some(page => page.component === 'crossword')
    ? [
        {
          path: '/crossword/leaderboard',
          name: 'crosswordLeaderboard',
          component: () => import('../views/crossword/CrosswordLeaderboard.vue'),
          meta: { title: "Crossword Clash - Leaderboard" }
        },
        {
          path: '/crossword/live',
          name: 'crosswordRace',
          component: () => import('../views/crossword/CrosswordRace.vue'),
          meta: { title: "Crossword Clash - Live Arena" }
        },
        {
          path: '/crossword/howToPlay',
          name: 'crosswordHowToPlay',
          component: () => import('../views/crossword/CrosswordHowToPlay.vue'),
          meta: { title: "Crossword Clash - Help" }
        },
      ]
    : []
  ),
  ...(config.layout.community.enable
    ? [
        {
          path: '/community',
          name: 'community',
          component: () => import('../views/community/CommunityHomeView.vue'),
          meta: { title: config.general.metadata.communityHome }
        },
        {
          path: '/community/posts',
          name: 'communityPosts',
          component: CommunityPostFeedView,
          meta: { title: config.general.metadata.communityBrowsePosts }
        },
        {
          path: '/community/submit',
          name: 'communityAddPost',
          component: () => import('../views/community/CommunityCreatePostView.vue'),
          meta: { title: config.general.metadata.communityCreatePost }
        },
        {
          path: '/community/about',
          name: 'communityAbout',
          component: () => import('../views/community/CommunityAboutView.vue'),
          meta: { title: config.general.metadata.communityAbout }
        },
        {
          path: '/community/guidelines',
          name: 'communityGuidelines',
          component: () => import('../views/community/CommunityGuidelinesView.vue'),
          meta: { title: config.general.metadata.communityGuidelines }
        },
        {
          path: '/community/posts/:postId',
          name: 'communityPost',
          component: CommunityPostFeedView,
          meta: { title: config.general.metadata.communityBrowsePosts }
        }
      ]
    : []
  ),
  {
    path: '/:catchAll(.*)',
    component: () => import('../views/misc/NotFound.vue'),
    meta: { title: config.general.metadata.catchAll }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});


router.afterEach((to) => {
  const routeChangeEvent = new Event('xFrame:route');
  window.dispatchEvent(routeChangeEvent);

  // Update title
  if (to.meta.title) {
    document.title = `${to.meta.title}`;
  }
});


export default router
