import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { id: "content" }
const _hoisted_2 = { class: "content-wrap py-0" }
const _hoisted_3 = {
  id: "slider",
  class: "slider-element slider-parallax min-vh-100 include-header"
}
const _hoisted_4 = { class: "section my-0 my-lg-5" }
const _hoisted_5 = { class: "container" }
const _hoisted_6 = { class: "row g-0 align-items-md-stretch align-items-lg-center" }
const _hoisted_7 = { class: "col-xl-7 col-md-6 img-overlap" }
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "section bg-color bg-opacity-10 my-0" }
const _hoisted_10 = { class: "container py-5" }
const _hoisted_11 = { class: "row gutter-50 text-center" }
const _hoisted_12 = { style: {"display":"flex","flex-direction":"column","height":"100%","position":"relative"} }
const _hoisted_13 = { style: {"flex":"1 0 auto"} }
const _hoisted_14 = { class: "entry-image mb-3" }
const _hoisted_15 = ["href"]
const _hoisted_16 = ["src"]
const _hoisted_17 = { style: {"flex":"0 0 auto","padding-top":"20px"} }
const _hoisted_18 = { class: "entry-title title-sm" }
const _hoisted_19 = ["href"]
const _hoisted_20 = { class: "entry-categories" }
const _hoisted_21 = ["href"]
const _hoisted_22 = {
  class: "entry-categories",
  style: {"font-size":".85rem","color":"#CCC","font-weight":"400"}
}
const _hoisted_23 = { class: "entry-content mt-3" }
const _hoisted_24 = ["href"]
const _hoisted_25 = { class: "section bg-transparent my-0 py-0" }
const _hoisted_26 = { class: "container-fluid" }
const _hoisted_27 = { class: "row align-items-md-center" }
const _hoisted_28 = { class: "row flex-md-row-reverse align-items-md-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("section", _hoisted_3, [
        _createElementVNode("div", {
          class: "slider-inner",
          style: _normalizeStyle(`background-image: url('${_ctx.appConfigs.assets.homeBannerImage}'); background-color: #FFF;`)
        }, _cache[0] || (_cache[0] = [
          _createStaticVNode("<div class=\"vertical-middle slider-element-fade\" data-v-da4681fc><div class=\"container text-start py-5\" data-v-da4681fc><div class=\"emphasis-title mb-4\" data-v-da4681fc><h1 class=\"text-uppercase fw-semibold\" style=\"font-size:56px;letter-spacing:8px;\" data-v-da4681fc>Your Home for<br data-v-da4681fc>All Things Bunny</h1><p class=\"lead mt-4 mb-0\" style=\"max-width:700px;line-height:1.7;\" data-v-da4681fc> Welcome to our free-roam bunny community! Check out our articles, vet reviews, and engage with fellow bunny enthusiasts in our community forums. </p></div></div></div>", 1)
        ]), 4)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("img", {
                src: _ctx.appConfigs.assets.homeAboutUsImage,
                alt: "...",
                class: "h-100",
                style: {"object-fit":"cover"}
              }, null, 8, _hoisted_8)
            ]),
            _cache[1] || (_cache[1] = _createStaticVNode("<div class=\"col-xl-5 col-md-6 bg-white\" data-v-da4681fc><div class=\"position-absolute start-0 top-0 w-100 h-100 bg-color bg-opacity-10\" data-v-da4681fc></div><p class=\"color op-06 text-uppercase ls-4 small mb-3\" data-v-da4681fc>Know More About Us</p><h2 class=\"display-6\" data-v-da4681fc>Your Home for All Things Bunny</h2><p class=\"color\" data-v-da4681fc> At Sincerely, Cinnabun, we&#39;re dedicated to promoting the welfare of pet rabbits. Check out our website for resources and catch <a href=\"https://www.youtube.com/c/SincerelyCinnabun\" target=\"_blank\" data-v-da4681fc>Cinnabun Sundays</a> on my YouTube channel for tips and more. </p><a href=\"/about\" class=\"button button-small border-color button-border m-0 color h-bg-color h-text-light\" data-v-da4681fc>About Us</a></div>", 1))
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("div", _hoisted_10, [
          _cache[2] || (_cache[2] = _createElementVNode("div", { class: "row justify-content-center mb-4" }, [
            _createElementVNode("div", { class: "col-md-6 text-center" }, [
              _createElementVNode("p", { class: "color text-uppercase ls-3 small mb-3" }, "Blog"),
              _createElementVNode("h3", { class: "display-5 mb-5" }, "Our Latest Posts")
            ])
          ], -1)),
          _createElementVNode("div", _hoisted_11, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.posts.slice(0, 3), (post) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "col-md-4 entry mb-0",
                key: post.id
              }, [
                _createElementVNode("article", _hoisted_12, [
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("div", _hoisted_14, [
                      _createElementVNode("a", {
                        href: "/post/" + post.attributes.title
                      }, [
                        _createElementVNode("img", {
                          src: post.attributes.thumbnail.data.attributes.formats.small.url,
                          loading: "lazy"
                        }, null, 8, _hoisted_16)
                      ], 8, _hoisted_15)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_17, [
                    _createElementVNode("div", _hoisted_18, [
                      _createElementVNode("h3", null, [
                        _createElementVNode("a", {
                          href: "/post/" + post.attributes.title,
                          class: "color-underline stretched-link"
                        }, _toDisplayString(post.attributes.title), 9, _hoisted_19)
                      ]),
                      _createElementVNode("div", _hoisted_20, [
                        _createElementVNode("a", {
                          href: "/post?category=" + post.attributes.category.data.attributes.name
                        }, _toDisplayString(post.attributes.category.data.attributes.name), 9, _hoisted_21)
                      ]),
                      _createElementVNode("div", _hoisted_22, _toDisplayString(_ctx.convertTimeToDate(post?.attributes.createdAt)), 1)
                    ]),
                    _createElementVNode("div", _hoisted_23, [
                      _createElementVNode("a", {
                        href: "/post/" + post.attributes.title,
                        class: "more-link"
                      }, "Read More", 8, _hoisted_24)
                    ])
                  ])
                ])
              ]))
            }), 128))
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_25, [
        _createElementVNode("div", _hoisted_26, [
          _cache[5] || (_cache[5] = _createElementVNode("div", { class: "clear" }, null, -1)),
          _createElementVNode("div", _hoisted_27, [
            _createElementVNode("div", {
              class: "col-md-6 px-lg-0 min-vh-50 min-vh-lg-75 d-flex align-self-stretch",
              style: _normalizeStyle(`background: url(${_ctx.appConfigs.assets.homeSectionImages[0]}) no-repeat center center; background-size: cover;`)
            }, null, 4),
            _cache[3] || (_cache[3] = _createElementVNode("div", { class: "col-md-6 p-5 p-lg-6" }, [
              _createElementVNode("h3", { class: "fs-2 fw-medium mb-4 px-lg-5" }, "Discover Rabbit Vets Near You"),
              _createElementVNode("p", { class: "px-lg-5 mb-5" }, [
                _createTextVNode(" Discover a vet directory tailored for rabbits in your area. We consolidate various sources to guarantee top-notch specialized care for your beloved pets. Leave a review to support your vet and assist fellow bunny owners in making informed decisions about their pet's healthcare. "),
                _createElementVNode("br"),
                _createElementVNode("br"),
                _createElementVNode("a", {
                  href: "/vets",
                  class: "read-more ls-1 text-uppercase border-bottom border-color small h-color h-op-08"
                }, "Read More")
              ])
            ], -1))
          ]),
          _cache[6] || (_cache[6] = _createElementVNode("div", { class: "clear" }, null, -1)),
          _createElementVNode("div", _hoisted_28, [
            _createElementVNode("div", {
              class: "col-md-6 px-lg-0 min-vh-50 min-vh-lg-75 d-flex align-self-stretch",
              style: _normalizeStyle(`background: url(${_ctx.appConfigs.assets.homeSectionImages[1]}) no-repeat center center; background-size: cover;`)
            }, null, 4),
            _cache[4] || (_cache[4] = _createElementVNode("div", { class: "col-md-6 p-5 p-lg-6" }, [
              _createElementVNode("h3", { class: "fs-2 fw-medium mb-4 px-lg-5" }, "Explore Our Community Forum"),
              _createElementVNode("p", { class: "px-lg-5 mb-5" }, [
                _createTextVNode(" Looking for reliable advice and support? Dive into our community forum, where experienced rabbit owners and experts share insights, tips, and stories to help you ensure the best care for your beloved bunnies. Join the conversation, ask questions, and connect with fellow rabbit enthusiasts today. "),
                _createElementVNode("br"),
                _createElementVNode("br"),
                _createElementVNode("a", {
                  href: "/community",
                  class: "read-more ls-1 text-uppercase border-bottom border-color small h-color h-op-08"
                }, "Read More")
              ])
            ], -1))
          ])
        ])
      ]),
      _cache[7] || (_cache[7] = _createStaticVNode("<div class=\"section bg-transparent my-0 py-0\" data-v-da4681fc><div class=\"container-fluid py-6\" data-v-da4681fc><div class=\"row justify-content-center mx-auto mw-md gx-5\" data-v-da4681fc><div class=\"col\" data-v-da4681fc><p class=\"h6 mb-0\" data-v-da4681fc> Content and advice shared on this platform regarding represent the opinions of our community members and should not be considered veterinary advice. We are not licensed veterinarians. For professional guidance concerning your rabbit&#39;s health or care, please consult a qualified veterinarian. If you have inquiries, wish to rectify misinformation, or seek clarification about our community, feel free to <a href=\"/about#contact\" data-v-da4681fc>Contact Us</a>. </p></div></div></div></div>", 1))
    ])
  ]))
}